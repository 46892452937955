<template>
  <mobile-screen :header="true" screen-class="resources-screen gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("services.service-items.item-info") }}
        </div>
        <template v-slot:right>
          <button
            @click="initSubmit"
            v-if="
              $route.name == 'r_edit-service-item' &&
                can('MANAGE_SERVICE_ITEMS', 'update')
            "
          >
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      :key="formKey"
      ref="editServiceItemForm"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      :initial-values="{
        ...selectedServiceItem,
      }"
      novalidate
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.has_translations }"
        >
          <div class="error-message" v-if="errors.has_translations">
            {{ errors.has_translations }}
          </div>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <div class="clebex-section-input-label">
                {{ displayLabelName("services.service-items.name") }}
              </div>
              <p class="text-label">
                {{ name }}
              </p>
            </div>
          </div>
          <Field
            name="has_translations"
            as="input"
            rules="required"
            type="hidden"
            :value="name"
          />
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.level }"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">
                {{ displayLabelName("services.service-items.type") }}
              </label>
              <p v-if="typeName" class="text-label">
                {{ typeName }}
              </p>
            </div>
          </div>
          <Field name="typeId" as="input" type="hidden" v-model="typeId" />
        </li>
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="hasQuantity"
                    type="checkbox"
                    v-model="hasQuantity"
                    disabled="true"
                  />
                  <label for="hasQuantity"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{ displayLabelName("services.service-items.has-quantity") }}
              </dd>
            </dl>
          </div>
        </li>
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="usesCostCode"
                    type="checkbox"
                    v-model="usesCostCode"
                    disabled="true"
                  />
                  <label for="usesCostCode"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{ displayLabelName("services.service-items.uses-cost-code") }}
              </dd>
            </dl>
          </div>
        </li>
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="costCodeRequired"
                    type="checkbox"
                    v-model="costCodeRequired"
                    disabled="true"
                  />
                  <label for="costCodeRequired"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{
                  displayLabelName("services.service-items.cost-code-required")
                }}
              </dd>
            </dl>
          </div>
        </li>
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="code">{{
                displayLabelName("services.service-items.code")
              }}</label>
              <Field
                id="code"
                name="code"
                as="input"
                type="text"
                v-model="code"
                disabled="true"
              />
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="desc">{{
                displayLabelName("services.service-items.description")
              }}</label>

              <span
                style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
              >
                {{ description }}</span
              >
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="price">{{
                displayLabelName("services.service-items.price")
              }}</label>
              <Field
                id="price"
                name="price"
                as="input"
                type="text"
                v-model="price"
                disabled="true"
              />
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="cost">{{
                displayLabelName("services.service-items.cost")
              }}</label>
              <Field
                id="cost"
                name="cost"
                as="input"
                type="text"
                v-model="cost"
                disabled="true"
              />
            </div>
          </div>
        </li>
        <!-- Service Image -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="serviceImage && serviceImage.data"
        >
          <img :src="serviceImage.data.url" style="width: 90%; margin: 5%;" />
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";

export default {
  name: "EditServiceItem",
  data() {
    return {
      formKey: 0,
      isSearchable: false,
    };
  },
  created() {
    this.setServiceType(null);
    this.getServiceItem(this.$route.params.order_item_id);
    this.getServiceImage(this.$route.params.order_item_id);
  },
  watch: {
    routeParam() {
      if (this.routeParam) {
        this.setServiceType(null);
        this.getServiceItem(this.$route.params.order_item_id);
        this.getServiceImage(this.$route.params.order_item_id);
      }
    },
    serviceItem() {
      const { serviceItem } = this;
      if (serviceItem && serviceItem.data) {
        this.hasQuantity = Boolean(serviceItem.data.has_quantity);
        this.usesCostCode = Boolean(serviceItem.data.uses_cost_code);
        this.costCodeRequired = Boolean(serviceItem.data.cost_code_required);
      }
    },
  },
  updated() {
    if (this.formKey === 10) {
      this.formKey = 0;
    } else {
      this.formKey++;
    }
  },
  computed: {
    ...mapState("service", ["serviceItem", "serviceImage", "serviceType"]),
    ...mapState("language", ["languages"]),
    ...mapState("companyProfile", ["companyProfile"]),

    routeParam() {
      return this.$route.params.order_item_id;
    },
    name() {
      if (
        this.serviceItem &&
        this.serviceItem.data &&
        this.serviceItem.data.name
      ) {
        return this.serviceItem.data.name;
      }
      return null;
    },
    typeId() {
      if (this.serviceType) {
        return this.serviceType.id;
      }
      if (
        this.serviceItem &&
        this.serviceItem.data &&
        this.serviceItem.data.service_type
      ) {
        return this.serviceItem.data.service_type.id;
      }
      return null;
    },
    typeName() {
      if (this.serviceType) {
        return this.serviceType.name;
      }
      if (
        this.serviceItem &&
        this.serviceItem.data &&
        this.serviceItem.data.service_type
      ) {
        return this.serviceItem.data.service_type.name;
      }
      return null;
    },
    code() {
      if (
        this.serviceItem &&
        this.serviceItem.data &&
        this.serviceItem.data.code
      ) {
        return this.serviceItem.data.code;
      }
      return null;
    },
    description() {
      if (
        this.serviceItem &&
        this.serviceItem.data &&
        this.serviceItem.data.description
      ) {
        return this.serviceItem.data.description;
      }
      return null;
    },
    price() {
      if (
        this.serviceItem &&
        this.serviceItem.data &&
        this.serviceItem.data.price
      ) {
        return this.serviceItem.data.price;
      }
      return null;
    },
    cost() {
      if (
        this.serviceItem &&
        this.serviceItem.data &&
        this.serviceItem.data.cost
      ) {
        return this.serviceItem.data.cost;
      }
      return null;
    },
  },
  methods: {
    ...mapActions("service", [
      "getServiceItem",
      "getServiceImage",
      "setServiceType",
    ]),
    resizeTextarea() {
      const textarea = this.$refs.textarea;
      textarea.style.height = "auto"; // Reset the height to auto
      textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to match the scrollHeight
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
  },
  beforeUnmount() {
    this.$store.commit("service/setServiceItem", null, {
      root: true,
    });
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  props: {
    backLinkName: {
      type: String,
      required: true,
    },
  },
};
</script>
